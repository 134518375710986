<template>
  <div>
    <div class="content-header">
      <h1>Assets</h1>
    </div>
    <assets-table :data="assets" />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import AssetsTable from './AssetsTable.vue';

export default {
  name: 'Assets',

  components: {
    AssetsTable,
  },

  computed: {
    ...mapGetters({
      assets: 'assets/getAll',
    }),
  },

  methods: {

  },
};
</script>

<style lang="css">

</style>
