<template>
  <div>
    <cv-data-table
      class="assets-table"
      :columns="columns"
      :data="tableData"
      :pagination="{ numberOfItems: fullData.length, pageSizes: [15, 50, 100] }"
      @pagination="onPagination"
      :zebra="false"
      :sortable="true"
      @sort="onSort"
      ref="table"
    >
      <template slot="actions">
        <cv-button-set>
          <cv-button
            small
            @click="addAsset"
          >
            Add
          </cv-button>
        </cv-button-set>
      </template>
      <template
        slot="data"
      >
        <cv-data-table-row
          v-for="(item, rowIndex) in tableData"
          :key="`${rowIndex}`"
          :value="`${rowIndex}`"
        >
          <cv-data-table-cell>
            {{ item.symbol }}
          </cv-data-table-cell>
          <cv-data-table-cell>
            <router-link
              :to="`/assets/${item.id}`"
              custom
              v-slot="{ navigate, href }"
            >
              <a
                :href="href"
                @click="navigate"
              >{{ item.name }}</a>
            </router-link>
          </cv-data-table-cell>
          <cv-data-table-cell>
            {{ formatFloat(item.sharesLeft) }}
          </cv-data-table-cell>
          <cv-data-table-cell>
            {{ formatCurrency(item.costOfSharesLeft) }}
          </cv-data-table-cell>
          <cv-data-table-cell>
            <cv-overflow-menu
              label=""
            >
              <cv-overflow-menu-item @click="editAsset(item)">
                Edit
              </cv-overflow-menu-item>
              <cv-overflow-menu-item danger>
                Delete
              </cv-overflow-menu-item>
            </cv-overflow-menu>
          </cv-data-table-cell>
        </cv-data-table-row>
      </template>
    </cv-data-table>
    <add-edit-asset ref="addEditAssetModal" />
    <!-- <edit-modal ref="editModal" /> -->
  </div>
</template>

<script>
import {
  CvDataTable,
  CvButton,
  CvButtonSet,
  CvDataTableCell,
  CvDataTableRow,
  CvOverflowMenu,
  CvOverflowMenuItem,
} from '@carbon/vue';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { formatCurrency, formatFloat } from '../../lib/utils';
import AddEditAsset from '../Asset/AddEdit.vue';

export default {
  name: 'AssetsTable',

  components: {
    CvDataTable,
    CvButton,
    CvButtonSet,
    CvDataTableCell,
    CvDataTableRow,
    CvOverflowMenu,
    CvOverflowMenuItem,
    AddEditAsset,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      columns: [
        'Symbol',
        'Name',
        'Quantity left',
        'Cost basis',
        '',
      ],
      pageStart: 1,
      pageNumber: 1,
      pageLength: 15,
      sortIndex: 0,
      sortOrder: 'ascending',
    };
  },

  computed: {
    ...mapGetters({
      getAsset: 'ledger/getAsset',
    }),

    fullData() {
      return this.data.map(({ symbol, name, id }) => {
        const asset = this.getAsset(id);
        return {
          costOfSharesLeft: asset?.costOfSharesLeft || 0,
          sharesLeft: asset?.sharesLeft || 0,
          name,
          symbol,
          id,
        };
      });
    },

    sortedData() {
      const order = this.sortOrder === 'ascending' ? 'asc' : 'desc';

      const properties = ['symbol', 'name', 'sharesLeft', 'costOfSharesLeft'];

      return _.orderBy(this.fullData, [properties[this.sortIndex]], [order]);
    },

    tableData() {
      return [...this.sortedData]
        .slice(this.pageStart - 1, this.pageStart + this.pageLength - 1);
    },

  },

  methods: {
    formatCurrency,

    formatFloat,

    onPagination({ start, page, length }) {
      this.pageNumber = page;
      this.pageStart = start;
      this.pageLength = length;
    },

    onSort({ index, order }) {
      this.sortIndex = index;
      this.sortOrder = order;
    },

    addAsset() {
      this.$refs.addEditAssetModal.show();
    },

    editAsset(item) {
      this.$refs.addEditAssetModal.show(item.id);
    },
  },
};
</script>

<style>

.assets-table td {
  width: 10rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.assets-table td:nth-of-type(2) {
  width: auto;
}

.assets-table .bx--table-column-menu {
  width: 4rem;
}
</style>
